import { createSlice } from "@reduxjs/toolkit";

const initTheme = {
    product_my: "",
    navCollapsed: false,
	sideNavTheme: "SIDE_NAV_LIGHT",
	locale: 'tr',
	navType: "SIDE",
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: "ltr"
  };

const themeSlice = createSlice({
    name: "theme",
    initialState: initTheme,
    reducers: {
        product: (state,action) => {
            state.product_my = action.payload
        },
        toggleCollapsedNav: (state, action) => {
            //state.navCollapsed = action.payload.navCollapsed
            state.navCollapsed = action.payload
        },
        onNavStyleChange: (state, action) => {
            state.sideNavTheme= action.payload
        },
        onLocaleChange: (state, action) => {
            state.locale= action.payload
        },
        onNavTypeChange: (state, action) => {
            state.navType= action.payload
        },
        onTopNavColorChange: (state, action) => {
            state.topNavColor= action.payload
        },
        onHeaderNavColorChange: (state, action) => {
            state.headerNavColor= action.payload
        },
        onMobileNavToggle: (state, action) => {
            state.mobileNav= action.payload
        },
        onSwitchTheme: (state, action) => {
            state.currentTheme= action.payload
        },
        onDirectionChange: (state, action) => {
            state.direction= action.payload
        },
  
    }
})

export const { product, toggleCollapsedNav, onNavStyleChange, onLocaleChange, onNavTypeChange, 
    onTopNavColorChange, onHeaderNavColorChange, onMobileNavToggle, onSwitchTheme, onDirectionChange 
} = themeSlice.actions
export default themeSlice.reducer