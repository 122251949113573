import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect, useDispatch, useSelector } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
//import { signOut } from 'redux/actions/Auth';
//import { auth } from "auth/FirebaseAuth_old";
//import { useAuthState } from "react-firebase-hooks/auth";
import { BASE_URL, publicRequest } from "redux/requestMethods";
import { signOutFailure, signOutStart, signOutSuccess } from "redux/authSlice";


const menuItem = [
	// {
	// 	title: "Edit Profile",
	// 	icon: EditOutlined ,
	// 	path: "/app/pages/setting?id=edit-profile"
  //   },
    
    {
		title: "Profil Ayarları",
		icon: SettingOutlined,
		path: "/app/apps/setting?id=edit-profile"
    },
  //   {
	// 	title: "Billing",
	// 	icon: ShopOutlined ,
	// 	path: "/app/pages/setting?id=billing"
	// },
  //   {
	// 	title: "Help Center",
	// 	icon: QuestionCircleOutlined,
	// 	path: "/app/pages/setting?id=edit-profile"
	// }
]

export const NavProfile = (/*{signOut}*/) => {
  
  //const [user, loading2, error2] = useAuthState(auth)
  
  const dispatch = useDispatch()
  
	const user = useSelector(state => state.auth)
  console.log("USERUSER name",user?.name, user?.email, user?.companyName, user?.logo)
  
  const profileImg = user.roles?.includes(2001) 
                        ? `${BASE_URL}product/uploads/${user?.companyName}/${user?.logo}` 
                        //? "/img/avatars/hacker.png" 
                        : user.roles?.includes(2000) 
                            ? `${BASE_URL}product/uploads/${user?.companyName}/${user?.logo}`  
                            : user.roles?.includes(1999) 
                                ? `${BASE_URL}product/uploads/${user?.companyName}/${user?.logo}` 
                                : `${BASE_URL}product/uploads/${user?.companyName}/${user?.logo}`    // 1998 USER


  const signOut_fnc = async() => {

    //const curr_usr = auth?._delegate?.currentUser
    
    //if (curr_usr){
      /* console.log("user wants LOG OUT1", auth)
      console.log("user wants LOG OUT2", curr_usr) */
      dispatch(signOutStart())

      await publicRequest.post("/user/logout")
        .then(response => {

         /*  auth.signOut()
            .then(() => console.log("Kullanıcı çıkş yaptı"))
            .catch(err => console.log("hata", err)) */

          dispatch(signOutSuccess())
          console.log("logged out")
        })
        .catch(err => {
            //dispatch(logoutFailure())
            dispatch(signOutFailure())
            console.log(err)
        })

    /* } else {
      console.log("NO USER",curr_usr)

    } */


    /*await axios1.post("/user/logout")
      .then(response => {
          dispatch(logoutSuccess())
          console.log("logged out")
      })
      .catch(err => {
          //dispatch(logoutFailure())
          console.log(err)
      })*/

  }

  const profile_func = () => {
    console.log("profile")
  }



  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {user.roles?.includes(2001)
            ? <Avatar style={{border:"3px solid red"}} size={45} src={profileImg} />
            : user.roles?.includes(2000)
              ? <Avatar style={{border:"3px solid green"}} size={45} src={profileImg} />
              : user.roles?.includes(1999)
                ? <Avatar style={{border:"3px solid blue"}} size={45} src={profileImg} />
                : <Avatar size={45} src={profileImg} />
            }
          
          <div className="pl-3">
            <h4 className="mb-0">{`${user.name} / ${user.companyName}`}</h4>
            <span className="text-muted">{user.roles?.includes(2001) ? "Super-Admin" : user.roles?.includes(2000) ? "Designer" : user.roles?.includes(1999) ? "Company-Admin" :"User"}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path} onClick={profile_func}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}

          <Menu.Item key={menuItem.length + 1} onClick={e => signOut_fnc()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Güvenli Çıkış</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <div >
            {user.roles?.includes(2001)
              ? <Avatar style={{border:"3px solid red"}} size={45} src={profileImg} />
              : user.roles?.includes(2000)
                ? <Avatar style={{border:"3px solid green"}} size={45} src={profileImg} />
                : user.roles?.includes(1999)
                  ? <Avatar style={{border:"3px solid blue"}} size={45} src={profileImg} />
                  : <Avatar size={45} src={profileImg} />
              }
          </div>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

//export default connect(null, {signOut})(NavProfile)

export default NavProfile
