import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
    name: "product",
    initialState: {
        products: [],
        foto_info: [],
        date_info: "",
        /*icerik_adi: "",
        aciklama: "",
        normal_fiyat: "",
        indirimli_fiyat: "",
        maliyet: "",
        vergi: "",
        foto_url: "",
        durum: "Hazırlanıyor",
        paylasim_tarihi: "",
        oncelik: "",
        kategoriler: "",*/
        
        isFetching : false, 
        error : false
    },
    reducers: {
        // ADD
        addProductStart: (state) => {
            state.isFetching = true
        },
        addProductSuccess: (state, action) => {
            state.isFetching = false
            state.products = action.payload
            /*state.icerik_adi = action.icerik_adi
            state.aciklama = action.aciklama
            state.normal_fiyat = action.normal_fiyat
            state.indirimli_fiyat = action.indirimli_fiyat
            state.maliyet = action.maliyet
            state.vergi = action.vergi
            state.foto_url = action.foto_url
            state.durum = action.durum
            state.paylasim_tarihi = action.paylasim_tarihi
            state.oncelik = action.oncelik
            state.kategoriler = action.kategoriler*/
        },
        addProductFailure: (state) => {
            state.isFetching = false
            state.error = true
        },

        //ADD FOTO
        addFotoSuccess: (state, action) => {
            state.isFetching = false
            //state.foto_info = action.payload
            state.foto_info = action.payload?.foto_info
            state.date_info = action.payload?.date_info
        },

        //GET
        getProductStart: (state) => {
            state.isFetching = true
        },
        getProductSuccess: (state, action) => {
            state.isFetching = false
            state.products = action.payload
        },
        getProductFailure: (state) => {
            state.isFetching = false
            state.error = true
        },
    }
})

export const { 
    addProductStart, addProductSuccess, addProductFailure,
    addFotoSuccess,
    getProductStart, getProductSuccess, getProductFailure
} = productSlice.actions
export default productSlice.reducer