import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';

//import { auth } from 'auth/FirebaseAuth_old'; 
//import { useAuthState } from "react-firebase-hooks/auth";

//import { AuthContext, AuthProvider } from "./auth-views/MyAuth"
//import { onAuthStateChanged } from "@firebase/auth";
import { Provider } from 'react-redux';
import { store } from "./../redux/store"


function RouteInterceptor({ children, isAuthenticated, hasAccToken, ...rest }) {
  //isAuthenticated = true  //TODO orinal hale getir
  //console.log("authcc", auth)
  //console.log("authccr", auth.currentUser)
  
  /*const myctx = useContext(AuthContext)
  const isAuthenticated = myctx.currentUser
  console.log("isAuthenticated", isAuthenticated)*/
  

  return (
    <Route
      {...rest}
      render={({ location }) =>
        (isAuthenticated && hasAccToken)
          ? ( children )
          : (
              <Redirect
                to={{
                    pathname: AUTH_PREFIX_PATH,
                    state: { from: location }
                  }}
              />
            )
      }
    />
  );
}

export const Views = (/*props*/) => {
  const myauth = useSelector(state => state.auth)
  const mytheme = useSelector(state => state.theme)

  //const [user, loading2, error2] = useAuthState(auth)
	/*console.log("USERX1 user: ", user)
	console.log("USERX2 loading2: ", loading2)
	console.log("USERX3 error2: ", error2)*/
	//console.log("USERX4 verified: ", user?.emailVerified)

  const locale = mytheme.locale
  const direction = mytheme.direction
  const isVerified = myauth.verified
  const hasToken = myauth.accessToken
  //console.log("USERX TOKEN: ",myauth)

  
  /* const [currentUser, setCurrentUser] = useState(null)
  console.log("currentUser ",currentUser)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, []) */


  const location = useLocation()

  //const { locale, token, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  
  useBodyClass(`dir-${direction}`);

    /* <AuthProvider value={currentUser} > {everything} </AuthProvider> */
  return (
    <Provider store={store} /* AuthProvider value={currentUser} */>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
          <Switch>

            <Route exact path="/">
              <Redirect to={APP_PREFIX_PATH} />
            </Route>

            <Route path={AUTH_PREFIX_PATH}>
              <AuthLayout direction={direction} />
            </Route>

            <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={isVerified} hasAccToken={hasToken}>
              <AppLayout direction={direction} location={location}/>
            </RouteInterceptor>

          </Switch>
        </ConfigProvider>
      </IntlProvider>
    </Provider>
  )
}


/*const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  console.log("locale: ",locale)
  console.log("direction: ",direction)
  const { token } = auth;
  return { locale, direction, token }
};*/

//export default withRouter(connect(mapStateToProps)(Views));
export default Views