import { 
  DashboardOutlined, 
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  DotChartOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
  ShareAltOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    /*{
      key: 'dashboards-default',
      path: `${APP_PREFIX_PATH}/dashboards/default`,
      title: 'sidenav.dashboard.default',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },*/
    {
      key: 'dashboards-analytic',
      path: `${APP_PREFIX_PATH}/dashboards/analytic`,
      title: 'sidenav.dashboard.analytic',
      icon: DotChartOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-sales',
      path: `${APP_PREFIX_PATH}/dashboards/sales`,
      title: 'sidenav.dashboard.sales',
      icon: FundOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const appsNavTree = [{
  key: 'apps',
  path: `${APP_PREFIX_PATH}/apps`,
  title: 'sidenav.apps',
  icon: AppstoreOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'apps-social-media-management',
      path: `${APP_PREFIX_PATH}/apps/social-media-management`,
      //title: 'sidenav.apps.social-media-management',
      title: 'sidenav.apps.socialMediaManagement',
      icon: ShareAltOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'apps-social-media-management-contentList',
          path: `${APP_PREFIX_PATH}/apps/social-media-management/content-list`,
          title: 'sidenav.apps.socialMediaManagement.contentList',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'apps-social-media-management-addContent',
          path: `${APP_PREFIX_PATH}/apps/social-media-management/add-content`,
          title: 'sidenav.apps.socialMediaManagement.addContent',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        /*{
          key: 'apps-social-media-management-editContent',
          path: `${APP_PREFIX_PATH}/apps/social-media-management/edit-content/`,
          title: 'sidenav.apps.socialMediaManagement.editContent',
          icon: '',
          breadcrumb: false,
          submenu: []
        }*/
      ]
    },
    {
      key: 'apps-mail',
      path: `${APP_PREFIX_PATH}/apps/mail/inbox`,
      title: 'sidenav.apps.mail',
      icon: MailOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'apps-chat',
      path: `${APP_PREFIX_PATH}/apps/chat`,
      title: 'sidenav.apps.chat',
      icon: MessageOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'apps-calendar',
      path: `${APP_PREFIX_PATH}/apps/calendar`,
      title: 'sidenav.apps.calendar',
      icon: CalendarOutlined,
      breadcrumb: true,
      submenu: []
    },

  ]
}]

const componentsNavTree = []

const extraNavTree = []

const docsNavTree = []


//console.log("appNav:", pre_appsNavTree[0].submenu)
//pre_appsNavTree[0].submenu.map((menu,idx)=>console.log(`appNav ${idx}:`,menu.role.find(i=>userRoles.includes(i))))

/*const appsNavTree = pre_appsNavTree[0].submenu.map(menu => {
  return menu.filter(i=> userRoles.includes(i.role))
  //return menu.role.filter(i => userRoles.includes(i))
})*/

/*const result2 = appsNavTree.map(menu => {
  //console.log("sub",menu.submenu)
  return menu.submenu.filter(sbm=>sbm.role.find(i => userRoles.includes(i)))
}).filter(f => f.length > 0)*/


const navigationConfig_user = [
  ...dashBoardNavTree,
  ...appsNavTree,
  ...componentsNavTree,
  ...extraNavTree,
  ...docsNavTree
]

export default navigationConfig_user;
