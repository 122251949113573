import React from 'react'
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import { connect, useSelector } from "react-redux";
import utils from 'utils';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile, navCollapsed, navType) => {

  //const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if(isMobile && !props.mobileLogo) {
    return 0
  }
  if(isNavTop) {
    return 'auto'
  }
  if(navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

const getLogo = (logoType, navCollapsed) => {
  //const { logoType } = props;
  if(logoType === 'light') {
    if(navCollapsed) {
      return '/img/fav_ra.png'
      //return '/img/logo-sm-white.png'
    }
    return '/img/logo_ra.png'
    //return '/img/logo-white.png'
  }

  if (navCollapsed) {
    return '/img/fav_ra.png'
    //return '/img/logo-sm.png'
  }
  return '/img/logo_ra.png' //'/img/rasoftlogoweb3.jpg'
}

const getLogoDisplay = (isMobile, mobileLogo) => {
  if(isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
}

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const mytheme = useSelector(state => state.theme)
  const navCollapsed = mytheme.navCollapsed
  const navType = mytheme.navType

  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)} 
      style={{width: `${getLogoWidthGutter(props, isMobile, navCollapsed, navType)}`}}>
      <a href='/'>
        <img src={getLogo(props.logoType, navCollapsed)} alt={`${APP_NAME} logo`}/>
      </a>
      
    </div>
  )
}

/* const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } =  theme;
  return { navCollapsed, navType }
}; */

//export default connect(mapStateToProps)(Logo);
export default Logo
