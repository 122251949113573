import React from 'react';
import { connect, useSelector } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import {
  Layout,
  Grid,
} from "antd";

import navigationConfig from "configs/NavigationConfig";
import navigationConfig_user from "configs/NavigationConfig_user";
import navigationConfig_tasarim from "configs/NavigationConfig_tasarim";

import { 
  SIDE_NAV_WIDTH, 
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from "react-css-theme-switcher";
import navigationConfig_company from 'configs/NavigationConfig_company';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ /* navCollapsed, navType, */ location/* , direction */ }) => {

  const mytheme = useSelector(state => state.theme)
  const navCollapsed = mytheme.navCollapsed
  const navType = mytheme.navType
  const direction = mytheme.direction

  const userRole = useSelector(state => state.auth?.roles)

  let navcnfg = []
  if (userRole?.includes(2001))   
    navcnfg = navigationConfig
  else if (userRole?.includes(2000)) 
    navcnfg = navigationConfig_tasarim
  else if (userRole?.includes(1999))  
    navcnfg = navigationConfig_company
  else // default: 1998
    navcnfg = navigationConfig_user  //USER- MÜŞTERİ
    


  const currentRouteInfo = utils.getRouteInfo(navcnfg, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if(isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if(direction === DIR_LTR) {
      return {paddingLeft: getLayoutGutter()}
    }  
    if(direction === DIR_RTL) {
      return {paddingRight: getLayoutGutter()}
    }
    return {paddingLeft: getLayoutGutter()}
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile}/>
      {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo}/> : null}
      <Layout className="app-container">
        {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo}/> : null }
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  )
}

/* const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } =  theme;
  return { navCollapsed, navType, locale }
}; */

//export default connect(mapStateToProps)(React.memo(AppLayout));
export default (React.memo(AppLayout));