/*const FirebaseConfig = {
  apiKey: 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM',
  authDomain: 'emilus.firebaseapp.com',
  databaseURL: 'https://emilus.firebaseio.com',
  projectId: 'emilus',
  storageBucket: 'emilus.appspot.com',
  messagingSenderId: '807555350717',
  appId: '1:807555350717:web:145ba7c21af47203a2f7d4',
  measurementId: 'G-8KE7HJB252'
};*/

const FirebaseConfig = {
  apiKey: 'AIzaSyBypqUrj47PyxmcChEFC_yzJ6tXfis6_PI',
  authDomain: 'rasoft-web.firebaseapp.com',
  //databaseURL: 'https://emilus.firebaseio.com',
  databaseURL: 'http://localhost:3000/', //TODO localhost ??
  projectId: 'rasoft-web',
  storageBucket: 'rasoft-web.appspot.com',
  messagingSenderId: '1000946435548',
  appId: '1:1000946435548:web:d479928c9c7f54908dfb19',
  measurementId: 'G-21QSG88D4G'
};

export default FirebaseConfig

