import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import navigationConfig from "configs/NavigationConfig";
import IntlMessage from 'components/util-components/IntlMessage';
import { useSelector } from 'react-redux';
import navigationConfig_tasarim from 'configs/NavigationConfig_tasarim';
import navigationConfig_user from 'configs/NavigationConfig_user';
import navigationConfig_company from 'configs/NavigationConfig_company';

const BreadcrumbRoute = withRouter(props => {
	const { location } = props;
	const pathSnippets = location.pathname.split('/').filter(i => i);

	let breadcrumbData = { 
		'/app' : <IntlMessage id="home" />
	};
	
	const userRole = useSelector(state => state.auth?.roles)
	console.log("userrrrole",userRole)
	
	const navConf = userRole?.includes(2001) 
						? navigationConfig 
						: userRole?.includes(2000) 
							? navigationConfig_tasarim 
							: userRole?.includes(1999)
								? navigationConfig_company
								: navigationConfig_user //1998


	navConf.forEach((elm, i) => {
		const assignBreadcrumb = (obj) => breadcrumbData[obj.path] = <IntlMessage id={obj.title} />;
		assignBreadcrumb(elm);
		if (elm.submenu) {
			elm.submenu.forEach( elm => {
				assignBreadcrumb(elm)
				if(elm.submenu) {
					elm.submenu.forEach( elm => {
						assignBreadcrumb(elm) 
					})
				}
			})
		}
	})

	const buildBreadcrumb = pathSnippets.map((_, index) => {
		const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
		return (
		<Breadcrumb.Item key={url}>
			<Link to={url}>{breadcrumbData[url]}</Link>
		</Breadcrumb.Item>
		);
	});
  
  return (
		<Breadcrumb>
			{buildBreadcrumb}
		</Breadcrumb>
  );
});

export class AppBreadcrumb extends Component {
	render() {
		return (
			<BreadcrumbRoute />
		)
	}
}

export default AppBreadcrumb
