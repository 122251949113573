import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useSelector } from "react-redux";

export const AppViews = () => {

  //const userRole = 1999  //useSelector(state => state.auth)
  const user = useSelector(state => state.auth)
  const userRole = user.roles
  //console.log("userRoles: user nav",user, userRole)


  return (
    <Suspense fallback={<Loading cover="content"/>}>
      {userRole?.includes(2001)
        ? <Switch>
            <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
            <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
            <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
            <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
            <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
            <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
            <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />
            <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
          </Switch>
        : userRole?.includes(2000)
          ? <Switch>
              <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
              <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
              <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
            </Switch>
          : userRole?.includes(1999)
            ? <Switch>
                <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
                <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
              </Switch>                         
            : <Switch>
                <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
                <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
              </Switch>

      }

      {/* <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch> */}
    </Suspense>
  )
}

export default React.memo(AppViews);
