import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Radio, Switch, Button, message } from 'antd';
import { 
	toggleCollapsedNav, 
	onNavTypeChange,
	onNavStyleChange,
	onTopNavColorChange,
	onHeaderNavColorChange,
	onSwitchTheme,
	onDirectionChange
} from './../../redux/themeSlice';
import { CopyOutlined } from '@ant-design/icons';
import ColorPicker from 'components/shared-components/ColorPicker';
import CopyToClipboard from 'react-copy-to-clipboard';
import NavLanguage from './NavLanguage';
import { 
	SIDE_NAV_LIGHT,
	NAV_TYPE_SIDE,
	NAV_TYPE_TOP,
	SIDE_NAV_DARK,
	DIR_RTL,
	DIR_LTR
} from 'constants/ThemeConstant';
import { useThemeSwitcher } from "react-css-theme-switcher";
import utils from 'utils/index';

const colorOptions = [
	'#3e82f7',
	'#24a772',
	'#de4436',
	'#924aca',
	'#193550'
]

const ListOption = ({name, selector, disabled, vertical}) => (
	<div className={`my-4 ${vertical? '' : 'd-flex align-items-center justify-content-between'}`}>
		<div className={`${disabled ? 'opacity-0-3' : ''} ${vertical? 'mb-3' : ''}`}>{name}</div>
		<div>{selector}</div>
	</div>
)

export const ThemeConfigurator = (/* { 
	//navType, 
	//sideNavTheme, 
	//navCollapsed,
	//topNavColor,
	//headerNavColor,
	//locale,
	//currentTheme,
	//direction,

	//toggleCollapsedNav, 
	//onNavTypeChange, 
	//onNavStyleChange,
	//onTopNavColorChange,
	//onHeaderNavColorChange,
	//onSwitchTheme,
	//onDirectionChange
} */) => {

	const dispatch = useDispatch()

	const userRole = useSelector(state => state.auth?.roles)

	const mytheme = useSelector(state => state.theme)
  	const navCollapsed = mytheme.navCollapsed
  	const navType = mytheme.navType
  	const sideNavTheme = mytheme.sideNavTheme
  	const topNavColor = mytheme.topNavColor
  	const headerNavColor = mytheme.headerNavColor
  	const locale = mytheme.locale
  	const currentTheme = mytheme.currentTheme
  	const direction = mytheme.direction
	console.log("themeconf_navcollapsed: ", navCollapsed)


	const isNavTop = navType === NAV_TYPE_TOP? true : false
	const isCollapse = navCollapsed 



	const { switcher, themes } = useThemeSwitcher();

	const toggleTheme = (isChecked) => {
		dispatch(onHeaderNavColorChange(''))
		const changedTheme = isChecked ? 'dark' : 'light'
		dispatch(onSwitchTheme(changedTheme))
    switcher({ theme: themes[changedTheme] });
  };

	const ontopNavColorClick = (value) => {
		dispatch(onHeaderNavColorChange(''))
		const { rgb } = value
		const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
		const hex = utils.rgbaToHex(rgba)
		dispatch(onTopNavColorChange(hex))
	}
	const onHeaderNavColorClick = (value) => {
		const { rgb } = value
		const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
		const hex = utils.rgbaToHex(rgba)
		dispatch(onHeaderNavColorChange(hex))
	}

	const onNavTypeClick = (value) => {
		dispatch(onHeaderNavColorChange(''))
		if(value === NAV_TYPE_TOP) {
			dispatch(onTopNavColorChange(colorOptions[0]))
			dispatch(toggleCollapsedNav(false))
		}
		dispatch(onNavTypeChange(value))
	}

	const genCopySettingJson = (configState) => JSON.stringify(configState, null, 2);

	return (
		<>
			<div className="mb-5">
				<h4 className="mb-3 font-weight-bold">Gezinme Çubuğu</h4>
				{
					isNavTop ?
					<ListOption 
						name="Üst Nav Rengi:"
						vertical
						selector={
							<ColorPicker color={topNavColor} colorChange={ontopNavColorClick}/>
						}
					/>
					:
					<ListOption 
						name="Header Nav Rengi:"
						vertical
						selector={
							<ColorPicker color={headerNavColor} colorChange={onHeaderNavColorClick}/>
						}
					/>
				}
				
				<ListOption 
					name="Gezinme Çubuğu Tipi:"
					selector={
						<Radio.Group 
							size="small" 
							onChange={e => onNavTypeClick(e.target.value)} 
							value={navType}
						>
							<Radio.Button value={NAV_TYPE_SIDE}>Yan</Radio.Button>
							<Radio.Button value={NAV_TYPE_TOP}>Üst</Radio.Button>
						</Radio.Group>
					}
				/>
				<ListOption 
					name="Yan Nav Rengi" //"Side Nav Color:"
					selector={
						<Radio.Group
							disabled={isNavTop}
							size="small" 
							onChange={e => dispatch(onNavStyleChange(e.target.value))} 
							value={sideNavTheme}
						>
							<Radio.Button value={SIDE_NAV_LIGHT}>Gündüz</Radio.Button>
							<Radio.Button value={SIDE_NAV_DARK}>Gece</Radio.Button>
						</Radio.Group>
					}
					disabled={isNavTop}
				/>
				<ListOption 
					name="Yan Nav Daralt" //"Side Nav Collapse:"
					selector={
						<Switch 
							disabled={isNavTop} 
							checked={isCollapse} 
							onChange={() => dispatch(toggleCollapsedNav(!navCollapsed))} 
						/>
					}
					disabled={isNavTop}
				/>
				<ListOption 
					name="Koyu Tema:"
					selector={
						<Switch checked={currentTheme === 'dark'} onChange={toggleTheme} />
					}
				/>
				<ListOption 
					name="Kullanım Yönü:"
					selector={
						<Radio.Group
							size="small" 
							onChange={e => dispatch(onDirectionChange(e.target.value))} 
							value={direction}
						>
							<Radio.Button value={DIR_LTR}>LTR</Radio.Button>
							<Radio.Button value={DIR_RTL}>RTL</Radio.Button>
						</Radio.Group>
					}
				/>
			</div>
			{ userRole?.includes(2001) && 
				<div className="mb-5">
					<h4 className="mb-3 font-weight-bold">Locale</h4>
					<ListOption 
						name="Dil:"
						selector={ <NavLanguage configDisplay/> }
					/> 
				</div>
			}

			{ userRole?.includes(2001) && 
				<div>
					<CopyToClipboard
						text={genCopySettingJson({ navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction})}
						onCopy={() => message.success('Copy Success, please paste it to src/configs/AppConfig.js THEME_CONFIG variable.') }
					>
						<Button icon={<CopyOutlined /> } block>
							<span>Copy Setting</span>
						</Button>
					</CopyToClipboard>
				</div> 
			}
		</>
	)
}

const mapStateToProps = ({ theme }) => {
  const { navType, sideNavTheme, /*navCollapsed,*/ topNavColor, headerNavColor, locale, currentTheme, direction } =  theme;
  return { navType, sideNavTheme, /*navCollapsed,*/ topNavColor, headerNavColor, locale, currentTheme, direction }
};

const mapDispatchToProps = {
	toggleCollapsedNav,
	onNavTypeChange,
	onNavStyleChange,
	onTopNavColorChange,
	onHeaderNavColorChange,
	onSwitchTheme,
	onDirectionChange
}

//export default connect(mapStateToProps, mapDispatchToProps)(ThemeConfigurator)
export default ThemeConfigurator
