import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'
import { useSelector } from "react-redux";

export const AppViews = () => {

  const verifiedUser = useSelector(state => state.auth?.verified)
  console.log("index user",verifiedUser)

  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        {/*<Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
        <Route path={`${AUTH_PREFIX_PATH}/login-1`} component={lazy(() => import(`./authentication/login-1`))} />*/}
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login-2`))} />
        {/*<Route path={`${AUTH_PREFIX_PATH}/register-1`} component={lazy(() => import(`./authentication/register-1`))} />*/}
        {/* <Route path={`${AUTH_PREFIX_PATH}/register`} component={lazy(() => import(`./authentication/register-2`))} /> */}
        <Route path={`${AUTH_PREFIX_PATH}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />
        <Route path={`${AUTH_PREFIX_PATH}/resetpassword/:token`} component={lazy(() => import(`./authentication/reset-password`))} />
        {/*<Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />*/}
        <Route path={`${AUTH_PREFIX_PATH}/error`} component={lazy(() => import(`./errors/error-page-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/verify-email`} component={lazy(() => import(`./authentication/verify-email`))} />
        <Route path={`${AUTH_PREFIX_PATH}/verify/:userId/:uniqueString`} component={lazy(() => import(`./authentication/verified-email`))} />
        <Route path={`${AUTH_PREFIX_PATH}/company-registration`} component={lazy(() => import(`./authentication/company-registration`))} />
        {verifiedUser && <Route path={`${AUTH_PREFIX_PATH}/person-signup`} component={lazy(() => import(`./components/PersonSignUp`))} />}

        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login` /* TODO url değiştir */} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

