import { createSlice } from "@reduxjs/toolkit";
import { isNull } from "lodash";

const initAuth = {
      loading: false,

      user_uid: "",
      companyUid: null,
      companyName: null,
      name: null,
      surname: null,
      title: null,
      email: null,
      dateOfBirth: "",
      phone: null,
      user_website: null,
      user_address: null,
      user_city: null,
      //register
      roles: [],
      accessToken: "",
      verified: false,

      taxNumber: null,
      sector: null,
      address: null,
      phoneNumber: null,
      companyEmail: null,
      logo: "",
      logo_thb: "",
      website: null,
      otherAccounts: null,

      message: '',
      showMessage: false,
      redirect: ''
  };

const authSlice = createSlice({
    name: "auth",
    initialState: initAuth,
    reducers: {
        // SIGN UP
        signUpStart: (state, action) => {
            state.loading = true
        },
	    signUpSuccess: (state, action) => {
            state.loading= false
            state.user_uid = action.payload._id
            state.companyUid = action.payload.companyUid
            state.companyName = action.payload.companyName
            state.name = action.payload.name
            state.surname = action.payload.surname
            state.email = action.payload.email
            state.dateOfBirth = action.payload.dateOfBirth
            state.phone = action.payload.phone
            state.user_website = action.payload.user_website
            state.user_address = action.payload.user_address
            state.user_city = action.payload.user_city

            state.accessToken= action.payload.accessToken
            state.roles = action.payload.roles
            state.verified = action.payload.verified
            state.logo = action.payload.logo
            state.logo_thb = action.payload.logo_thb
	    },
        signUpFailure: (state, action) => {
            state.loading = false
            state.error = true
        },
        // SIGN UP


        //SIGN IN
        signInStart: (state, action) => {
            state.loading = true
        },
	    signInSuccess: (state, action) => {
            state.loading= false
            state.user_uid = action.payload._id
            state.companyUid = action.payload.companyUid
            state.companyName = action.payload.companyName
            state.name = action.payload.name
            state.surname = action.payload.surname
            state.title = action.payload.title
            state.email = action.payload.email
            state.dateOfBirth = action.payload.dateOfBirth
            state.phone = action.payload.phone
            state.user_website = action.payload.user_website
            state.user_address = action.payload.user_address
            state.user_city = action.payload.user_city
            
            state.roles = action.payload.roles
            state.verified = action.payload.verified
            state.accessToken= action.payload.accessToken
            
            state.taxNumber = action.payload.taxNumber
            state.sector = action.payload.sector
            state.address = action.payload.address
            state.phoneNumber = action.payload.phoneNumber
            state.companyEmail = action.payload.companyEmail
            state.logo = action.payload.logo
            state.logo_thb = action.payload.logo_thb
            state.website = action.payload.website
            state.otherAccounts = action.payload.otherAccounts

	    },
        signInFailure: (state, action) => {
            state.loading = false
            state.error = true
        },
        //SIGN IN

        //UPDATE
        updateCompany: (state,action) => {
            state.taxNumber = action.payload.taxNumber
            state.sector = action.payload.sector
            state.address = action.payload.address
            state.phoneNumber = action.payload.phoneNumber
            state.companyEmail = action.payload.companyEmail
            state.logo = action.payload.logo
            state.logo_thb = action.payload.logo_thb
            state.website = action.payload.website
            state.otherAccounts = action.payload.otherAccounts
        },

        mailVerified: (state, action) => {
            state.verified =  action.payload
        },

        //SIGN OUT
        signOutStart: (state, action) => {
            state.loading = true
	    },
	    signOutSuccess: (state, action) => {
            state.user_uid = null
            state.companyUid = null
            state.companyName = null
            state.name = null
            state.surname = null
            state.title = null
            state.email = null
            state.dateOfBirth = ""
            state.phone = null
            state.user_website = null
            state.user_address = null
            state.user_city = null

            state.roles= []
            state.verified= false
            state.accessToken= isNull
            
            state.taxNumber = null
            state.sector = null
            state.address = null
            state.phoneNumber = null
            state.companyEmail = null
            state.logo= ""
            state.logo_thb= ""
            state.website = null
            state.otherAccounts = null

            state.redirect= '/'
            state.loading= false
	    },
        signOutFailure: (state, action) => {
            state.loading= false
            state.error = true
        },
        //SIGN OUT

        //ACESSTOKEN
        updateToken: (state, action) => {
            state.accessToken = action.payload
        },

        authenticated: (state, action) => {
            state.loading= false
            state.redirect= '/'
            //state.accessToken= action.payload.accessToken
            //state.accessToken= action.payload
	    },
        showAuthMessage:  (state, action) => {
            state.message= action.payload//.message
            state.showMessage= true
            state.loading= false
	    },
        hideAuthMessage:  (state, action) => {
            state.message= ''
            state.showMessage= false
	    },

        
	    showLoading: (state, action) => {
            state.loading= true
	    },
	    signInWithGoogle: (state, action) => {
            /*state.loading= false
            state.accessToken= action.accessToken*/
	    },
	    signInWithGoogleAuthenticated: (state, action) => {
            state.loading= false
            state.accessToken= action.payload.accessToken
	    },
	    signInWithFacebook: (state, action) => {
            /*state.loading= false
            state.accessToken= action.accessToken*/
	    },
	    signInWithFacebookAuthenticated: (state, action) => {
            state.loading= false
            state.accessToken= action.payload.accessToken
	    },
        onForgetPasswordClick: (state, action) => {
            //state.
        }
  
    }
})

export const { 
    signUpStart, signUpSuccess, signUpFailure, 
    signInStart, signInSuccess, signInFailure, 
    updateCompany,
    mailVerified, 
    signOutStart, signOutSuccess, signOutFailure, 
    updateToken, 
    authenticated, 
    showAuthMessage, hideAuthMessage, 
    showLoading, 
    signInWithGoogle, signInWithGoogleAuthenticated, 
    signInWithFacebook, signInWithFacebookAuthenticated, 
    onForgetPasswordClick 
} = authSlice.actions

export default authSlice.reducer