import axios from "axios";

//export const BASE_URL = "http://localhost:5001/"
export const BASE_URL = "https://api.rasoft.dev/" //şu anda bu
//export const BASE_URL = "https://api.rasoft.com.tr/"

export const publicRequest = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
})
export const fotoRequest = axios.create(
    { baseURL: BASE_URL },
    { headers: { "Content-Type": "multipart/form-data" } }
)

export const userRequest = axios.create({
    baseURL: BASE_URL,
    //headers: {token: `Bearer ${TOKEN}`}
})

export const axiosPrvt = axios.create({
    baseURL: BASE_URL,
    //headers: { 'Content-Type': 'aplication/json', "Access-Control-Allow-Credentials": true, },
    withCredentials: true, //cookie işlemi için gerekir
    //credentials: "include",   //! neden ne için kullanılır/kullanılmaz
})