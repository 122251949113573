import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { createStore, applyMiddleware, compose } from "redux";  //ESKİ

import { persistStore, persistReducer,
  FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import themeSlice from "redux/themeSlice";
import authSlice from "redux/authSlice";
import productSlice from "redux/productSlice";
import reducers from "../reducers"; //ESKİ

import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";
import sessionStorage from "redux-persist/es/storage/session";


const persistConfig = {
  key: "root",
  storage//: sessionStorage
}

const rootReducer = combineReducers({
  theme: themeSlice,
  auth: authSlice,
  product: productSlice
  //..
})

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => 
      getDefaultMiddleware({ 
        thunk: false,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
       }), 
                  //sagaMiddleware
                  
});
//sagaMiddleware.run(rootSaga);


//export default store
export let persistor = persistStore(store) 
//TODO uygulama açıldığında store daha kendine gelmediyse, 
  //ki böyle durumda giriş yapmamıza rağmen bizi login sayfasına yönlendriebilir. 
  //Bu durumda PersistGate  func. kullanmalıyız.


  /*const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store2 = createStore(reducers, preloadedState, composeEnhancers(
    applyMiddleware(...middlewares)
  ));*/
